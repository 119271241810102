<template>
  <div>
    <el-upload
      :action="upLoadUrl"
      list-type="picture-card"
      :on-preview="handlePictureCardPreview"
      :on-remove="handleRemove"
      :http-request="uploadSectionFile"
      :before-upload="handleBeforeUpload"
      :file-list="fileLists"
      :limit="limit"
    >
      <i class="el-icon-plus"></i>
    </el-upload>
    <el-dialog width="60%" :visible.sync="disShowImgs" append-to-body>
      <el-image style="width: 100%; height: 70vh" :src="dialogImageUrl">
      </el-image>
    </el-dialog>
  </div>
</template>

<script>
import { Api } from "@/assets/js/api";
import tools from "@/assets/js/tool";
export default {
  props: {
    fileNameLists: [Array],
    limit: {
      typeof: Number,
      default: 6,
    },
    // 大小限制(MB)
    fileSize: {
      type: Number,
      default: 5,
    },
    // 文件类型, 例如["doc", "xls", "ppt", "txt", "pdf"]
    fileType: {
      type: Array,
      default: () => ["png", "jpg", "jpeg"],
    },
  },
  data() {
    return {
      upLoadUrl: "", //上传地址
      dialogImageUrl: "",
      //   fileNameList: [],
      fileLists: [],
      disShowImgs: false,
    };
  },
  watch: {
    fileNameLists: {
      immediate: true,
      handler: function (newVal, oldVa) {
        this.fileLists = newVal || [];
      },
      deep: true,
    },
  },
  created() {
    this.uploadUrl = this.common.uploadUrl;
  },
  methods: {
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.disShowImgs = true;
    },
    handleRemove(file, fileList) {
      this.fileLists = fileList || [];
      this.changeFileList(this.fileLists);
    },
    uploadSectionFile(param) {
      const file = param.file;
      const fileName = file.name;
      const form = new FormData(); //  根据后台需求数据格式
      form.append("file", file); //  文件对象
      form.append("fileName", fileName); //  本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
      let signArr = [
        {
          key: "fileName",
          val: fileName,
        },
      ];

      let sign = tools.getMD5Sign(signArr);
      form.append("sign", sign);
      // return;
      this.$showLoading();
      Api.uploadFile(form)
        .then((res) => {
          if (res.data.status === "success") {
            //   this.fileNameList.push(res.data.result.fileName);
            let obj = {
              name: res.data.result.fileName,
              url: res.data.result.url,
            };
            this.fileLists.push(obj);
            this.changeFileList(this.fileLists);
          } else {
            this.$message.error(res.data.message);
          }
          this.$hideLoading();
        })
        .catch(() => {
          this.$hideLoading();
        });
    },
    // 上传前校检格式和大小
    handleBeforeUpload(file) {
      // 校检文件类型
      if (this.fileType && file) {
        let fileExtension = "";
        if (file.name.lastIndexOf(".") > -1) {
          fileExtension = file.name.slice(file.name.lastIndexOf(".") + 1);
        }
        const isTypeOk = this.fileType.some((type) => {
          if (file.type.indexOf(type) > -1) return true;
          if (fileExtension && fileExtension.indexOf(type) > -1) return true;
          return false;
        });
        if (!isTypeOk & file) {
          this.$message.error(
            `文件格式不正确, 请上传${this.fileType.join("/")}格式文件!`
          );
          return false;
        }
      }
      // 校检文件大小
      if (this.fileSize && file) {
        const isLt = file.size / 1024 / 1024 < this.fileSize;
        if (!isLt) {
          this.$message.error(`上传文件大小不能超过 ${this.fileSize} MB!`);
          return false;
        }
      }
      return true;
    },
    // 文件列表改变的时候，更新组件的v-model的文的数据
    changeFileList(fileList) {
      //   let tempFileList = [];
      //   fileList.forEach((item) => {
      //     tempFileList.push(item.fileName);
      //   });
      this.$emit("input", fileList);
    },
  },
};
</script>

<style>
</style>