import { render, staticRenderFns } from "./LogoSettings.vue?vue&type=template&id=51d23f8a&scoped=true&"
import script from "./LogoSettings.vue?vue&type=script&lang=js&"
export * from "./LogoSettings.vue?vue&type=script&lang=js&"
import style0 from "./LogoSettings.vue?vue&type=style&index=0&id=51d23f8a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51d23f8a",
  null
  
)

export default component.exports