<template>
  <div>
    <el-form
      style="margin-top: 50px"
      label-position="left"
      label-width="180px"
      ref="addForm"
      :model="addFormDatas"
    >
      <el-form-item label="租户系统名称">
        <el-input
          style="width: 200px"
          type="input"
          placeholder="请输入"
          v-model="addFormDatas.tenantSystemName"
        ></el-input>
      </el-form-item>

      <el-form-item label="菜单左上角的 logo (1张)">
        <uploadimgs
          @input="changeFileList"
          :fileNameLists="fileNameLists"
          :limit="1"
        ></uploadimgs>
      </el-form-item>
      <el-form-item label="登录页 logo (1张)">
        <uploadimgs
          @input="changeFileList2"
          :fileNameLists="fileNameLists2"
          :limit="1"
        ></uploadimgs>
      </el-form-item>
      <el-form-item label="登录页背景图(1张)">
        <uploadimgs
          @input="changeFileList3"
          :fileNameLists="fileNameLists3"
          :limit="1"
        ></uploadimgs>
      </el-form-item>
    </el-form>

    <div class="saveBtns">
      <el-button class="glo_bgcMainColor" @click="addLogo">保存</el-button>
    </div>
  </div>
</template>
<script>
import uploadimgs from "@/components/upLoadImgs.vue";
export default {
  inject: ["reload"],
  components: {
    uploadimgs,
  },
  data() {
    return {
      fileNameLists: [],
      fileNameLists2: [],
      fileNameLists3: [],
      addFormDatas: {
        tenantSystemName: "",
      },
    };
  },
  watch: {},
  created() {},
  mounted() {
    this.logoInfo();
  },
  activated() {},
  destroyed() {},
  methods: {
    changeFileList(array) {
      this.fileNameLists = array;
    },
    changeFileList2(array) {
      this.fileNameLists2 = array;
    },
    changeFileList3(array) {
      this.fileNameLists3 = array;
    },
    // 获取
    logoInfo() {
      this.$API.getSystemVI().then((res) => {
        let datas = res.data.result || "";
        console.log("datas", datas);

        this.addFormDatas.tenantSystemName = datas.tenantSystemName;
        // 租户logo (左上角)
        if (datas.tenantLogoUrl) {
          this.fileNameLists = [
            {
              name: datas.tenantLogo || "",
              url: datas.tenantLogoUrl || "",
            },
          ];
        }
        // 租户登陆页logo
        if (datas.tenantAdminLoginLogoUrl) {
          this.fileNameLists2 = [
            {
              name: datas.tenantAdminLoginLogo || "",
              url: datas.tenantAdminLoginLogoUrl || "",
            },
          ];
        }
        // tenantAdminLoginBgpic  租户管理端登陆页背景图 名称
        // tenantAdminLoginBgpicUrl  租户管理端登陆页背景图Url

        // tenantAdminLoginLogo  租户登陆页logo 名称
        // tenantAdminLoginLogoUrl  租户登陆页logo Url

        // tenantLogo  租户logo 名称
        // tenantLogoUrl  租户logo Url

        // tenantSystemName  租户系统名称
      });

      //   this.$API.logoInfo().then((res) => {
      //     let rs = res.data.result || "";
      //     if (rs) {
      //       let obj = {
      //         name: rs.fileName,
      //         url: rs.url,
      //       };
      //       this.fileNameLists = [obj];
      //     }
      //   });
    },
    // 保存
    addLogo() {
      console.log("fileNameLists", this.fileNameLists);
      if (this.fileNameLists.length < 1) {
        this.$message.error("请上传左上角logo");
        return false;
      }
      if (this.fileNameLists2.length < 1) {
        this.$message.error("请上传登录logo");
        return false;
      }
      if (!this.addFormDatas.tenantSystemName) {
        this.$message.error("请填写系统名称");
        return false;
      }

      this.$API
        .updateSystemVI({
          tenantAdminLoginBgpic:
            this.fileNameLists3.length > 0 ? this.fileNameLists3[0].name : "",
          tenantAdminLoginLogo: this.fileNameLists2[0].name,
          tenantLogo: this.fileNameLists[0].name,
          tenantSystemName: this.addFormDatas.tenantSystemName,
        })
        .then((res) => {
          this.$message.success(res.data.message);
        });

      //   this.$API
      //     .addLogo({
      //       fileName: this.fileNameLists[0].name,
      //     })
      //     .then((res) => {
      //       this.$message.success(res.data.message);
      //       this.logoInfo();
      //     });
    },
  },
};
</script>
<style scoped lang='scss'>
.saveBtns {
  width: 100%;
  text-align: center;
  margin-top: 50px;
}
</style>